import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const responsiveDefault = {
	xl: {
		breakpoint: { max: 5000, min: 1920 },
		items: 5,
		partialVisibilityGutter: 50
	},
	lg: {
		breakpoint: { max: 1919, min: 1280 },
		items: 5,
		partialVisibilityGutter: 20
	},
	md: {
		breakpoint: { max: 1279, min: 960 },
		items: 4,
		partialVisibilityGutter: 20
	},
	sm: {
		breakpoint: { max: 959, min: 600 },
		items: 3,
		partialVisibilityGutter: 50
	},
	xs: {
		breakpoint: { max: 599, min: 0 },
		items: 2,
		partialVisibilityGutter: 50
	}
};

const CarouselContainer = ({
	responsive,
	data,
	Title,
	PrimaryComponent,
	SecondaryComponent,
	isCenter,
	isContainer,
	showDots,
	dotListClass,
	arrows,
}) => {
	return (
		<div className={`${isContainer ? 'pt-16 bg-white w-full max-w-screen-xl mx-auto' : ''}`}>
			<style>
				{`
					.min-size-arrow {
						min-width: 24px;
						min-height: 24px;
					}

					.react-multiple-carousel__arrow {
						z-index: 10 !important;
					}
				`}
			</style>

			{Title && <Title />}

			<div className={`${isContainer ? 'relative px-5 sm:px-10 lg:px-32' : ''}`}>
				{data && data.length && (
					<Carousel
						swipeable={true}
						draggable={true}
						responsive={responsive || responsiveDefault}
						arrows={arrows}
						showDots={showDots}
						dotListClass={dotListClass}
						partialVisible={false}
						className={`${isCenter
							? 'md:flex md:justify-center'
							: isContainer
								? 'lg:mx-12'
								: ''
						}`}
						customRightArrow={
							<KeyboardArrowRightIcon
								className="react-multiple-carousel__arrow min-size-arrow text-pink-500 bg-transparent"
								style={{ right: -7 }}
							/>
						}
						customLeftArrow={
							<KeyboardArrowLeftIcon
								className="react-multiple-carousel__arrow min-size-arrow text-pink-500 bg-transparent"
								style={{ left: -7 }}
							/>
						}
					>
						{data.map((item, i) => (
							PrimaryComponent
								? <PrimaryComponent key={i} item={item} />
								: null
						))}
					</Carousel>
				)}

				{SecondaryComponent && <SecondaryComponent />}
			</div>
		</div>
	);
};

CarouselContainer.propTypes = {
	data: PropTypes.array.isRequired,
	PrimaryComponent: PropTypes.func.isRequired,
};

CarouselContainer.defaultProps = {
	responsive: null,
	Title: null,
	SecondaryComponent: null,
	isCenter: false,
	isContainer: true,
	showDots: false,
	dotListClass: '',
	arrows: true,
};

export default CarouselContainer;
